<template>
  <div class="edit-bannar-swiper-box">

    <!-- 操作 -->
    <selfCell title="操作">
      <el-button-group>
        <el-tooltip
          class="item"
          effect="dark"
          content="上一個"
          placement="top"
        >
          <el-button
            @click="checkItem('up')"
            type="primary"
            icon="el-icon-arrow-left"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="下一個"
          placement="top"
        >
          <el-button
            type="primary"
            @click="checkItem('down')"
            icon="el-icon-arrow-right"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="新增"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addItem"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="刪除"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="removeItem"
          ></el-button>
        </el-tooltip>
      </el-button-group>
    </selfCell>

    <!-- swiper設置 -->
    <div class="cell-b">
      <h4>輪播設置</h4>
      <!-- 是否輪播 -->
      <selfCell title="是否開啟輪播">
        <el-switch
          @change="setValue('autoplay')"
          v-model="configs.autoplay"
          active-text="開啟"
          inactive-text="關閉"
        >
        </el-switch>
      </selfCell>
      <!-- 秒 -->
      <selfCell
        v-if="configs.autoplay"
        title="輪播間隔時長"
      >
        <el-select
          v-model="configs.interval"
          @change="setValue('interval')"
        >
          <el-option
            label="1S"
            :value="1"
          ></el-option>
          <el-option
            label="2S"
            :value="2"
          ></el-option>
          <el-option
            label="3S"
            :value="3"
          ></el-option>
          <el-option
            label="4S"
            :value="4"
          ></el-option>
        </el-select>
      </selfCell>
      <!-- 方式 -->
      <selfCell title="輪播動畫">
        <el-select
          v-model="configs.effect"
          @change="setValue('effect')"
        >
          <el-option
            label="左右切換"
            value="slide"
          ></el-option>
          <el-option
            label="淡入淡出"
            value="fade"
          ></el-option>
        </el-select>
      </selfCell>
    </div>

    <!-- 背景圖 -->
    <selfCell title="背景圖">
      <selfUpload
        v-model="configs.item[index].bg"
        type="default"
        @change="setValue('item')"
      />
    </selfCell>
    <selfCell title="移動端背景圖">
      <selfUpload
        v-model="configs.item[index].bgYD"
        type="default"
        @change="setValue('item')"
      />
    </selfCell>
    <selfCell title="設置導航欄顏色">
      <el-color-picker
        v-model="configs.item[index].tabColor"
        @change="setValue('item')"
      />
    </selfCell>
    <!--  主圖 -->
    <selfCell title="主圖">
      <selfUpload
        v-model="configs.item[index].img.url"
        type="default"
        @change="setValue('item')"
      />
    </selfCell>
    <selfCell title="移動端主圖">
      <selfUpload
        v-model="configs.item[index].img.urlYD"
        type="default"
        @change="setValue('item')"
      />
    </selfCell>
    <!-- 圖片描述 -->
    <selfCell title="圖片描述">
      <el-input
        type="textarea"
        rows="3"
        v-model="configs.item[index].img.alt"
        placeholder="請輸入圖片描述"
      />
    </selfCell>
    <!-- 居左還是居右 -->
    <selfCell title="展示方式">
      <el-select
        v-model="configs.item[index].position"
        @change="setValue('item')"
      >
        <el-option
          label="內容在左"
          value="left"
        ></el-option>
        <el-option
          label="內容在右"
          value="right"
        ></el-option>
      </el-select>
    </selfCell>
    <!-- 內容 -->
    <div class="cell-b">
      <h4>標題</h4>
      <selfCell title="文字顏色">
        <el-color-picker
          v-model="configs.item[index].titleColor"
          show-alpha
          @change="setValue('item')"
        ></el-color-picker>
      </selfCell>
      <selfFontSize
        title="PC字體大小"
        v-model="configs.item[index].pcTitleFontSize"
        @change="setValue('item')"
      />
      <selfFontSize
        title="移動端字體大小"
        v-model="configs.item[index].titleFontSize"
        @change="setValue('item')"
      />
      <selfFontSize
        title="pad字體大小"
        v-model="configs.item[index].padTitleFontSize"
        @change="setValue('item')"
      />
      <el-input
        v-for="lItem in language"
        :key="lItem.code"
        v-model="configs.item[index][`${lItem.code=='HK'?'':lItem.code}title`]"
        :placeholder="`請輸入-${lItem.name}`"
        type="textarea"
        @change="setValue('item')"
      />
    </div>
    <div class="cell-b">
      <h4>詳情</h4>
      <el-color-picker
        v-model="configs.item[index].descColor"
        show-alpha
        @change="setValue('item')"
      ></el-color-picker>
      <selfFontSize
        title="PC字體大小"
        v-model="configs.item[index].pcDescFontSize"
        @change="setValue('item')"
      />
      <selfFontSize
        title="移動端字體大小"
        v-model="configs.item[index].descFontSize"
        @change="setValue('item')"
      />
      <selfFontSize
        title="pad字體大小"
        v-model="configs.item[index].padDescFontSize"
        @change="setValue('item')"
      />
      <el-input
        v-for="lItem in language"
        :key="lItem.code"
        v-model="configs.item[index][`${lItem.code=='HK'?'':lItem.code}desc`]"
        :placeholder="`請輸入-${lItem.name}`"
        type="textarea"
        rows="5"
        @change="setValue('item')"
      />
    </div>

    <div class="cell-b">
      <h4>按鈕組</h4>
      <div
        v-for="(bItem,bIndex) in configs.item[index].btns"
        :key="bIndex"
        class="cell-b"
      >

        <!-- 樣式 -->
        <selfCell title="樣式">
          <el-select
            v-model="bItem.style"
            @change="setValue('item')"
          >
            <el-option
              label="實體"
              value="default"
            ></el-option>
            <el-option
              label="線性"
              value="plain"
            ></el-option>
          </el-select>
        </selfCell>
        <selfCell :title="bItem.style == 'default' ? '主題色' : '文字色'">
          <el-color-picker
            v-model="bItem.color"
            show-alpha
            @change="setValue('item')"
          ></el-color-picker>
        </selfCell>
        <selfCell
          v-if="bItem.style == 'plain'"
          title="邊框色"
        >
          <el-color-picker
            v-model="bItem.color5"
            show-alpha
            @change="setValue('item')"
          ></el-color-picker>
        </selfCell>
        <template v-else>
          <selfCell title="鼠標懸浮色">
            <el-color-picker
              v-model="bItem.colorHover"
              show-alpha
              @change="setValue('item')"
            ></el-color-picker>
          </selfCell>
          <selfCell title="文字色">
            <el-color-picker
              v-model="bItem.textColor"
              show-alpha
              @change="setValue('item')"
            ></el-color-picker>
          </selfCell>
        </template>
        <selfCell
          title="內容"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="bItem[`${lItem.code=='HK'?'':lItem.code}title`]"
            @input="setValue('item')"
            :placeholder="`請輸入-${lItem.name}`"
          />
        </selfCell>
        <selfCell title="動作">
          <selfPagePicker
            v-model="bItem.go"
            @onChange="setValue('item')"
          />
        </selfCell>
      </div>
    </div>

  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import { getByName } from '@/js/utils/default_config';
export default {
  name: "edit-bannar-swiper",
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return 'title';
      },
    },
    configs: {
      default () {
        return {}
      }
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
    checkItem (type) {
      let items = this.configs.item;
      // *上移
      if (type == 'up') {
        if (this.index > 0) {
          let now = this.configs.item.splice(this.index, 1)
          this.configs.item.splice(this.index - 1, 0, now[0])
          this.setValue('item')
        }
      } else {
        if (this.index < this.configs.item.length - 1) {
          let now = this.configs.item.splice(this.index, 1)
          this.configs.item.splice(this.index + 1, 0, now[0])
          this.setValue('item')
        }
      }
    },
    addItem () {
      let swiper = getByName('bannarSwiper')
      let item = swiper.configs.item[0]
      this.$emit('addItem', { index: this.index, item: item, key: 'item' })
    },
    removeItem () {
      this.$emit('delItem', { index: this.index, key: 'item' })
    }
  }
}
</script>

<style lang="less" scoped>
</style>